//@ts-check
import { Box } from "@material-ui/core";
import React from "react";
import Layout from "../components/Layout/Layout";
import Seo from "../components/SEO/SEO";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Box py="70px" textAlign="center">
      <h1>Không tìm thấy trang yêu cầu 🙁</h1>
    </Box>
  </Layout>
);

export default NotFoundPage;
